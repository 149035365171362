import { instance } from '@/services/avik-api-gateway'
import { VehicleWithdrawal } from '@/types/vehicle-withdrawal'
import { AxiosResponse } from 'axios'

export function getVehicleWithdrawalGroup(
  uuid: string
): Promise<
  AxiosResponse<{
    uuid: string
    comment: string
    vehicleUuid: string
    vehicleWithdrawals: VehicleWithdrawal[]
  }>
> {
  return instance.get(`/vehicle-withdrawal-group/${uuid}`)
}
