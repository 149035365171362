<template>
  <q-card style="max-width: 100%">
    <q-card-section horizontal>
      <q-card-section
        v-if="hasVehicleWithdrawalGroup"
        style="border-right: 1px solid rgba(0, 0, 0, 0.16); width: 200px"
        class="q-pa-sm"
      >
        <div class="flex items-center column" v-if="fetchingGroup">
          <q-spinner color="primary" size="3em" />
          <div class="q-mt-sm">Hämtar avställningar...</div>
        </div>
        <q-list separator style="padding-top: 8px" v-else>
          <q-item
            @click="() => onSelectVehicleWithdrawal(vehicleWithdrawal.uuid)"
            :active="selectedVehicleWithdrawalUuid === vehicleWithdrawal.uuid"
            :active-class="$style.activeClass"
            clickable
            v-ripple
            v-for="(vehicleWithdrawal, $i) in vehicleWithdrawalGroupList"
            :key="$i"
          >
            <q-item-section>Avställning {{ $i + 1 }}</q-item-section>
          </q-item>
        </q-list>
      </q-card-section>
      <q-card-section style="width: 900px" class="q-pa-none">
        <q-form
          ref="formRef"
          @submit.prevent="onSubmit"
          autocomplete="off"
          greedy
        >
          <q-card-section class="row items-center text-h6">
            {{ formModal.state.mode === 'create' ? 'Skapa' : 'Redigera' }}
            avställning</q-card-section
          >

          <q-separator />

          <q-card-section class="q-pa-none"> </q-card-section>
          <div class="row">
            <div class="col-6 q-pa-md">
              <div class="row q-pb-md">
                <div class="col">
                  <div>
                    <AppFieldSelect
                      emit-value
                      map-options
                      v-model="model.vehicleUuid"
                      option-value="uuid"
                      v-if="vehicles.length"
                      :option-label="
                        (x) =>
                          `${x.name} ${
                            x.vehicleType ? '(' + x.vehicleType.name + ')' : ''
                          }`
                      "
                      label="Fordon"
                      :options="vehicles"
                      :rules="[requiredRule]"
                    />
                  </div>
                  <q-slide-transition>
                    <div
                      class="row items-center text-red-6"
                      v-if="!validVehicle"
                    >
                      <q-icon
                        name="mdi-information-outline"
                        class="q-mr-sm"
                        size="xs"
                      />
                      Fordonet period är inte längre gilltig
                    </div>
                  </q-slide-transition>
                  <q-slide-transition>
                    <div
                      class="row items-center text-red-6"
                      v-if="periodOverlappSameVehicle"
                    >
                      <q-icon
                        name="mdi-information-outline"
                        class="q-mr-sm"
                        size="xs"
                      />
                      Detta fordonet är redan avställd på denna period.
                    </div>
                  </q-slide-transition>
                </div>
              </div>
              <div class="row">
                <div class="col-5 q-pr-md q-pb-md">
                  <AppFieldSelect
                    emit-value
                    map-options
                    v-model="model.vehicleWithdrawalResponsibleUuid"
                    option-value="uuid"
                    option-label="name"
                    label="Ansvarig"
                    :options="responsibles"
                    :rules="[requiredRule]"
                  />
                </div>
                <div class="col-7">
                  <AppFieldSelect
                    emit-value
                    map-options
                    v-model="model.vehicleWithdrawalDepotUuid"
                    option-value="uuid"
                    option-label="name"
                    label="Avställningsdepå"
                    :options="depots"
                    :rules="[requiredRule]"
                  />
                </div>
              </div>
              <div class="row q-pb-md">
                <div class="col">
                  <AppFieldSelect
                    v-model="reason1"
                    label="Anledning till avställning"
                    :options="reasons1"
                    :rules="[requiredRule]"
                  />
                </div>
              </div>
              <div class="row q-pb-md">
                <div class="col">
                  <AppFieldSelect
                    v-model="reason2"
                    label="Orsak 1"
                    :disable="!reason1"
                    :options="reasons2"
                    :rules="[requiredRule]"
                  />
                </div>
              </div>
              <div class="row q-pb-md">
                <div class="col">
                  <AppFieldSelect
                    v-model="reason3"
                    label="Orsak 2"
                    :disable="!reason2"
                    :options="reasons3"
                    :rules="[requiredRule]"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <q-input
                    type="textarea"
                    rows="3"
                    v-model="model.comment"
                    :input-style="{ resize: 'none' }"
                    outlined
                    square
                    label="Kommentar"
                  />
                </div>
              </div>
            </div>
            <div class="col-6 q-pa-md">
              <div class="row q-pb-md">
                <div class="col">
                  <q-select
                    label="FORD Skadenummer"
                    v-model="model.ford"
                    use-input
                    use-chips
                    multiple
                    @new-value="onFordInput"
                    hide-dropdown-icon
                    input-debounce="0"
                    new-value-mode="add"
                    outlined
                    square
                    hint="Skriv in skadenummer och tryck ENTER för att lägga till"
                  />

                  <!-- <q-input
                outlined
                square
                counter
                :maxlength="FORD_MIN_MAX_LENGTH"
                mask="############"
                v-model.number="model.ford"
                @update:model-value="
                  (v) => {
                    if (v === '') {
                      model.ford = null
                    }
                  }
                "
                label="FORD Skadenummer "
                :rules="[model.ford && minFordLengthRule]"
              /> -->
                </div>
              </div>

              <div class="row q-pb-md">
                <div class="col-8">
                  <AppFieldDate
                    label="Avställd"
                    v-model="withdrawnDate"
                    :rules="[validDateRule]"
                  />
                </div>
                <div class="col-4">
                  <q-input
                    outlined
                    square
                    type="time"
                    v-model="withdrawnTime"
                    :rules="[requiredRule]"
                  />
                </div>
              </div>

              <div class="row q-pb-md">
                <div class="col-8">
                  <AppFieldDate
                    label="Prognos"
                    v-model="prognosisDate"
                    :rules="[validDateRule]"
                  />
                </div>
                <div class="col-4">
                  <q-input
                    outlined
                    square
                    type="time"
                    v-model="prognosisTime"
                    :rules="[requiredRule]"
                  />
                </div>
                <div class="col-12">
                  <q-slide-transition>
                    <div
                      class="row items-center text-red-6"
                      v-if="!isPrognosisAfterWithdrawn"
                    >
                      <q-icon
                        name="mdi-information-outline"
                        class="q-mr-sm"
                        size="xs"
                      />
                      Prognos måste börja efter avställning!
                    </div>
                  </q-slide-transition>
                </div>
              </div>

              <div class="row q-pb-md">
                <div class="col">
                  <AppFieldSelect
                    v-model="model.prognosisCertainty"
                    label="Prognos säkerhet"
                    :options="prognosisCertainties"
                    :rules="[requiredRule]"
                  />
                </div>
              </div>

              <div class="row q-pn-md">
                <div class="col">
                  <q-toggle
                    v-model="showReady"
                    label="Aktivera påställd"
                    left-label
                  />
                </div>
              </div>

              <transition
                enter-active-class="animated animate__fadeIn"
                leave-active-class="animated animate__fadeOut"
                mode="out-in"
                :duration="70"
              >
                <div class="row q-pb-md" style="height: 92px" v-if="showReady">
                  <div class="col-8">
                    <AppFieldDate
                      label="Påställd"
                      v-model="readyDate"
                      :rules="[validDateRule]"
                    />
                  </div>
                  <div class="col-4">
                    <q-input
                      outlined
                      square
                      type="time"
                      v-model="readyTime"
                      :rules="[requiredRule]"
                    />
                  </div>
                  <div class="col-12">
                    <q-slide-transition>
                      <div
                        class="row items-center text-red-6"
                        v-if="!isReadyAfterWithdrawn"
                      >
                        <q-icon
                          name="mdi-information-outline"
                          class="q-mr-sm"
                          size="xs"
                        />
                        Påställning måste börja efter avställning!
                      </div>
                    </q-slide-transition>
                  </div>
                </div>
              </transition>
            </div>
          </div>
          <q-separator />

          <q-card-actions align="right">
            <q-btn
              flat
              label="Avbryt"
              @click="formModal.closeModal"
              type="button"
              :disable="loading"
            />
            <q-btn
              :loading="loading"
              v-if="
                vehicleWithdrawalGroupList.length &&
                formModal.state.mode === 'update' &&
                showReady &&
                selectedVehicleWithdrawalUuid ===
                  vehicleWithdrawalGroupList[
                    vehicleWithdrawalGroupList.length - 1
                  ].uuid
              "
              @click="addVehicleWithdrawalGroup"
              >Uppdatera & Skapa ny</q-btn
            >
            <q-btn
              :label="formModal.state.mode === 'create' ? 'Skapa' : 'Uppdatera'"
              type="submit"
              color="primary"
              :loading="loading"
            />
          </q-card-actions>
        </q-form>
      </q-card-section>
    </q-card-section>
  </q-card>
</template>

<script lang="ts">
import { UseModal } from '@/composable/useModal'
import { QForm } from 'quasar'
import {
  UnwrapRef,
  computed,
  defineComponent,
  inject,
  onBeforeMount,
  ref,
  watch,
  toRaw,
} from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { VehicleWithdrawal } from '@/types/vehicle-withdrawal'
import AppFieldSelect from '@/components/AppFieldSelect.vue'
import AppFieldDate from '@/components/AppFieldDate.vue'
import { useVehicle } from '@/composable/useVehicle'
import { useVehicleWithdrawalResponsible } from '@/composable/useVehicleWithdrawalResponsible'
import { useVehicleWithdrawalDepot } from '@/composable/useVehicleWithdrawalDepot'
import { useVehicleWithdrawalReason } from '@/composable/useVehicleWithdrawalReason'
import { VehicleWithdrawalReason } from '@/types/vehicle-withdrawal-reason'
import {
  required as requiredRule,
  minCharactersRule,
  validDateRule,
} from '@/common/formValidationRules'
import { addMinutes, addMonths, format, isAfter } from 'date-fns'
import { useVehicleWithdrawal } from '@/composable/useVehicleWithdrawal'
import orderBy from 'lodash.orderby'
import { useValidVehicle } from '@/composable/useValidVehicle'
import { getVehicleWithdrawalGroup } from '@/api/vehicle-withdrawal/getVehicleWithdrawalGroup'

const FORD_MIN_MAX_LENGTH = 8

interface Acc {
  [key: string]: string
}

export default defineComponent({
  name: 'VehicleWithdrawalFormModal',

  components: {
    AppFieldSelect,
    AppFieldDate,
  },

  setup() {
    const { data: allVehicles } = useVehicle()
    const { data: validVehicles, fetchAll: fetchValidVehicles } =
      useValidVehicle()
    const { data: responsibles } = useVehicleWithdrawalResponsible()
    const { data: depots } = useVehicleWithdrawalDepot()
    const { data: reasons } = useVehicleWithdrawalReason()
    const {
      add: createVehicleWithdrawal,
      update: updateVehicleWithdrawal,
      loading: loadingStateVehicleWithdrawls,
      data: vehicleWithdrawals,
    } = useVehicleWithdrawal()
    const fetchingGroup = ref(true)
    const withdrawnDate = ref<string>(format(new Date(), 'yyyy-MM-dd'))
    const withdrawnTime = ref<string>(format(new Date(), 'HH:mm'))
    const prognosisDate = ref<string>(format(new Date(), 'yyyy-MM-dd'))
    const prognosisTime = ref<string | null>(null)
    const readyDate = ref<string>(format(new Date(), 'yyyy-MM-dd'))
    const readyTime = ref<string | null>(null)

    const formModal = inject<UseModal<VehicleWithdrawal>>('form-modal')
    const formRef = ref<QForm | null>(null)

    const showReady = ref(false)

    const reason1 = ref<string | null>(null)
    const reason2 = ref<string | null>(null)
    const reason3 = ref<string | null>(null)

    const createEmptyState = (): Partial<VehicleWithdrawal> => {
      showReady.value = false
      reason1.value = null

      withdrawnDate.value = format(new Date(), 'yyyy-MM-dd')
      withdrawnTime.value = format(new Date(), 'HH:mm')

      prognosisDate.value = format(new Date(), 'yyyy-MM-dd')
      prognosisTime.value = null

      readyDate.value = format(new Date(), 'yyyy-MM-dd')
      readyTime.value = null

      return {
        uuid: uuidv4(),
        vehicleUuid: null,
        vehicleWithdrawalResponsibleUuid: '',
        vehicleWithdrawalDepotUuid: '',
        vehicleWithdrawalReasonUuid: '',
        ford: [],
        comment: null,
        withdrawn: new Date(),
        ready: null,
        prognosis: null,
        prognosisCertainty: 'Osäker',
        vehicleWithdrawalGroup: null,
      }
    }

    const model = ref<Partial<VehicleWithdrawal>>(createEmptyState())

    const hasVehicleWithdrawalGroup = ref(!!model.value.vehicleWithdrawalGroup)

    const vehicleWithdrawalGroupList = ref<VehicleWithdrawal[]>([])
    const vehicleWithdrawalGroup = ref<{
      uuid: string
      vehicleUuid: string
      comment: string
    } | null>(null)
    const selectedVehicleWithdrawalUuid = ref<string | null>(null)

    const createReasonWatchers = () => {
      const reason1Watch = watch(reason1, () => {
        reason2.value = null
        reason3.value = null
      })

      const reason2Watch = watch(reason2, () => {
        reason3.value = null
      })

      const reason3watch = watch(reason3, () => {
        if (reason1.value && reason2.value && reason3.value) {
          const reason = reasons.value.find(
            (reason) =>
              reason.reason1 === reason1.value &&
              reason.reason2 === reason2.value &&
              reason.reason3 === reason3.value
          )
          if (reason) {
            model.value.vehicleWithdrawalReasonUuid = reason.uuid
          }
        } else {
          model.value.vehicleWithdrawalReasonUuid = ''
        }
      })

      return [reason1Watch, reason2Watch, reason3watch]
    }

    const reasonWatchers = ref(createReasonWatchers())

    const setModelFromData = (vehicleWithdrawal: VehicleWithdrawal) => {
      createEmptyState()
      model.value.uuid = vehicleWithdrawal.uuid
      model.value.vehicleUuid = vehicleWithdrawal.vehicleUuid
      model.value.vehicleWithdrawalResponsibleUuid =
        vehicleWithdrawal.vehicleWithdrawalResponsibleUuid
      model.value.vehicleWithdrawalReasonUuid =
        vehicleWithdrawal.vehicleWithdrawalReasonUuid
      model.value.vehicleWithdrawalDepotUuid =
        vehicleWithdrawal.vehicleWithdrawalDepotUuid
      model.value.ford = vehicleWithdrawal.ford
      model.value.comment = vehicleWithdrawal.comment
      model.value.withdrawn = vehicleWithdrawal.withdrawn
      model.value.ready = vehicleWithdrawal.ready
      model.value.prognosis = vehicleWithdrawal.prognosis
      model.value.prognosisCertainty = vehicleWithdrawal.prognosisCertainty
      model.value.vehicleWithdrawalGroup =
        vehicleWithdrawal.vehicleWithdrawalGroup || null
      reason1.value = vehicleWithdrawal.vehicleWithdrawalReason?.reason1 || null
      reason2.value = vehicleWithdrawal.vehicleWithdrawalReason?.reason2 || null
      reason3.value = vehicleWithdrawal.vehicleWithdrawalReason?.reason3 || null
      reasonWatchers.value.forEach((unwatch) => {
        unwatch()
      })
      reasonWatchers.value = createReasonWatchers()
      withdrawnDate.value = format(
        new Date(vehicleWithdrawal.withdrawn),
        'yyyy-MM-dd'
      )
      withdrawnTime.value = format(
        new Date(vehicleWithdrawal.withdrawn),
        'HH:mm'
      )

      if (vehicleWithdrawal.prognosis) {
        prognosisDate.value = format(
          new Date(vehicleWithdrawal.prognosis),
          'yyyy-MM-dd'
        )
        prognosisTime.value = format(
          new Date(vehicleWithdrawal.prognosis),
          'HH:mm'
        )
      }

      if (vehicleWithdrawal.ready) {
        readyDate.value = format(
          new Date(vehicleWithdrawal.ready),
          'yyyy-MM-dd'
        )
        readyTime.value = format(new Date(vehicleWithdrawal.ready), 'HH:mm')
        showReady.value = true
      }
    }

    const onSelectVehicleWithdrawal = (uuid: string) => {
      if (selectedVehicleWithdrawalUuid.value === uuid) return
      selectedVehicleWithdrawalUuid.value = uuid
      const vehicleWithdrawal = vehicleWithdrawalGroupList.value.find(
        (x) => x.uuid === uuid
      )
      if (vehicleWithdrawal) {
        setModelFromData(vehicleWithdrawal)
      }

      if (formModal?.state) {
        formModal.state.mode = 'update'
      }
    }

    const addVehicleWithdrawalGroup = async () => {
      const isValid = await isValidForm()
      if (!isValid) return

      if (formModal?.state.mode === 'update') {
        await updateVehicleWithdrawal(model.value)
      }
      if (model.value.vehicleWithdrawalGroup?.uuid) {
        const { data } = await getVehicleWithdrawalGroup(
          model.value.vehicleWithdrawalGroup.uuid
        )
        vehicleWithdrawalGroupList.value = data.vehicleWithdrawals
      }

      const prevVehicleWithdrawal = vehicleWithdrawalGroupList.value.find(
        (x) => x.uuid === selectedVehicleWithdrawalUuid.value
      )

      const prevReady = prevVehicleWithdrawal?.ready
      selectedVehicleWithdrawalUuid.value = null
      if (formModal?.state && prevVehicleWithdrawal) {
        reasonWatchers.value.forEach((unwatch) => {
          unwatch()
        })
        showReady.value = false
        readyTime.value = null
        formModal.state.mode = 'create'
        model.value.ready = null
        model.value.uuid = uuidv4()
        model.value.prognosis = null
        prognosisTime.value = null
        model.value.vehicleWithdrawalResponsibleUuid = ''
        model.value.vehicleWithdrawalDepotUuid = ''
        if (prevReady) {
          model.value.withdrawn = format(
            addMinutes(new Date(prevReady), 1),
            'yyyy-MM-dd HH:mm'
          ) as unknown as UnwrapRef<Date>
          withdrawnDate.value = format(
            new Date(model.value.withdrawn),
            'yyyy-MM-dd'
          )
          withdrawnTime.value = format(new Date(model.value.withdrawn), 'HH:mm')
        }
        if (vehicleWithdrawalGroup.value) {
          model.value.vehicleWithdrawalGroup = vehicleWithdrawalGroup.value
        }
        reasonWatchers.value = createReasonWatchers()
      }
    }

    const init = async () => {
      if (
        formModal?.state.mode === 'update' &&
        model.value.vehicleWithdrawalGroup?.uuid
      ) {
        fetchingGroup.value = true
        const { data } = await getVehicleWithdrawalGroup(
          model.value.vehicleWithdrawalGroup.uuid
        )
        vehicleWithdrawalGroup.value = {
          uuid: data.uuid,
          comment: data.comment,
          vehicleUuid: data.vehicleUuid,
        }
        vehicleWithdrawalGroupList.value = data.vehicleWithdrawals
        selectedVehicleWithdrawalUuid.value = model.value?.uuid || ''
        fetchingGroup.value = false
      }
    }

    const periodOverlappSameVehicle = computed(() => {
      if (!model.value.vehicleUuid) return false
      return vehicleWithdrawals.value.some((x) => {
        return (
          x.uuid !== model.value.uuid &&
          x.vehicleUuid === model.value.vehicleUuid &&
          +new Date(model.value.withdrawn || new Date()) <=
            (x.ready ? +new Date(x.ready) : +new Date('3000-01-01')) &&
          +new Date(x.withdrawn) <=
            (model.value.ready
              ? +new Date(model.value.ready)
              : +new Date('3000-01-01'))
        )
      })
    })

    // set default vehicleWithdrawalResponsibleUuid
    watch(
      responsibles,
      (value) => {
        if (formModal?.state.mode !== 'update') {
          const defaultResponsible = value.find((x) => x.name === 'EM')
          if (defaultResponsible) {
            model.value.vehicleWithdrawalResponsibleUuid =
              defaultResponsible.uuid
          }
        }
      },
      {
        immediate: true,
      }
    )

    // If update modal set event to model
    onBeforeMount(() => {
      if (formModal?.state.data && formModal?.state.mode === 'update') {
        setModelFromData(formModal.state.data)
        hasVehicleWithdrawalGroup.value = !!model.value.vehicleWithdrawalGroup
      }

      init()
    })

    // watch(showReady, (v) => {
    //   if (v) {
    //     readyDate.value = format(new Date(), 'yyyy-MM-dd')
    //     readyTime.value = format(new Date(), 'HH:mm')
    //   } else {
    //     model.value.ready = null
    //   }
    // })

    watch(
      () => withdrawnDate.value,
      (v) => {
        model.value.withdrawn = new Date(`${v} ${withdrawnTime.value}`)
        fetchValidVehicles({ from: v, to: v })
      },
      {
        immediate: true,
      }
    )

    watch(withdrawnTime, (v) => {
      model.value.withdrawn = new Date(`${withdrawnDate.value} ${v}`)
    })

    watch(prognosisDate, (v) => {
      model.value.prognosis = new Date(`${v} ${prognosisTime.value}`)
    })

    watch(prognosisTime, (v) => {
      model.value.prognosis = new Date(`${prognosisDate.value} ${v}`)
    })

    watch(readyDate, (v) => {
      model.value.ready = new Date(`${v} ${readyTime.value}`)
    })

    watch(readyTime, (v) => {
      if (!v) {
        model.value.ready = null
        return
      }
      model.value.ready = new Date(`${readyDate.value} ${v}`)
    })

    const validVehicle = ref(true)

    watch(
      () => model.value.vehicleUuid,
      () => {
        validVehicle.value = allVehicles.value.some(
          (x) =>
            model.value.vehicleUuid === x.uuid &&
            x.vehicleValidPeriods.some(
              (x) =>
                +new Date(x.periodStart) <= +new Date() &&
                +new Date() <= +new Date(x.periodEnd)
            )
        )
      }
    )

    const isValidForm = async () => {
      const isValid = await formRef.value?.validate()
      if (
        !isValid ||
        (model.value.ready ? !isReadyAfterWithdrawn.value : false) ||
        !isPrognosisAfterWithdrawn.value ||
        !validVehicle.value ||
        periodOverlappSameVehicle.value
      )
        return false
      return true
    }

    const onSubmit = async () => {
      const isValid = await isValidForm()
      if (!isValid) return

      if (!showReady.value) {
        model.value.ready = null
      }

      if (formModal?.state.mode === 'create') {
        if (!model.value.vehicleWithdrawalGroup) {
          model.value.vehicleWithdrawalGroup = {
            uuid: uuidv4(),
            vehicleUuid: model.value.vehicleUuid as string,
            comment: ' ',
          }
        }
        await createVehicleWithdrawal(
          toRaw({
            ...model.value,
          })
        )
      }

      if (formModal?.state.mode === 'update') {
        const payload = { ...model.value }
        if (!payload.vehicleWithdrawalGroup) {
          delete payload.vehicleWithdrawalGroup
        }
        await updateVehicleWithdrawal(payload)
      }

      formModal?.closeModal()
    }

    const reasons1 = computed(() => {
      return orderBy(
        Object.values(
          reasons.value.reduce<Acc>((acc, reason) => {
            const key = reason.reason1 as keyof VehicleWithdrawalReason
            if (!acc[key]) {
              acc[key] = reason.reason1
            }
            return acc
          }, {})
        ),
        [(x) => x],
        ['asc']
      )
    })

    const reasons2 = computed(() => {
      return orderBy(
        Object.values(
          reasons.value.reduce<Acc>((acc, reason) => {
            if (reason.reason1 !== reason1.value) return acc
            const key = reason.reason2 as keyof VehicleWithdrawalReason
            if (!acc[key]) {
              acc[key] = reason.reason2
            }
            return acc
          }, {})
        ),
        [(x) => x],
        ['asc']
      )
    })

    const reasons3 = computed(() => {
      return orderBy(
        Object.values(
          reasons.value.reduce<Acc>((acc, reason) => {
            if (
              reason.reason1 !== reason1.value ||
              reason.reason2 !== reason2.value
            )
              return acc
            const key = reason.reason3 as keyof VehicleWithdrawalReason
            if (!acc[key]) {
              acc[key] = reason.reason3
            }
            return acc
          }, {})
        ),
        [(x) => x],
        ['asc']
      )
    })

    const prognosisCertainties = ['Säker', 'Ganska säker', 'Osäker']

    const loading = computed(() => {
      return (
        loadingStateVehicleWithdrawls.value.create ||
        loadingStateVehicleWithdrawls.value.update
      )
    })

    const isReadyAfterWithdrawn = computed(() => {
      return (
        model.value.ready &&
        model.value.withdrawn &&
        isAfter(new Date(model.value.ready), new Date(model.value.withdrawn))
      )
    })

    const isPrognosisAfterWithdrawn = computed(() => {
      return (
        model.value.prognosis &&
        model.value.withdrawn &&
        isAfter(
          new Date(model.value.prognosis),
          new Date(model.value.withdrawn)
        )
      )
    })

    const vehicles = computed(() => {
      if (formModal && formModal.state.mode === 'update') {
        return orderBy(
          [
            ...validVehicles.value,
            ...allVehicles.value
              .filter((x) => x.uuid === formModal.state?.data?.vehicleUuid)
              .filter((x) => {
                const monthAgo = addMonths(new Date(), -1)
                return !x.vehicleValidPeriods.some(
                  (x) => +new Date(x.periodEnd) < +monthAgo
                )
              }),
          ],
          ['name'],
          ['asc']
        )
      }

      return orderBy(validVehicles.value, ['name'], ['asc'])
    })

    const onFordInput = (val: string, done: (val: string) => void) => {
      const parsedValue = val.replace(/\D/g, '')
      if (!parsedValue) return
      done(parsedValue)
    }

    return {
      loading,
      minFordLengthRule: minCharactersRule(FORD_MIN_MAX_LENGTH),
      validDateRule,
      requiredRule,
      onFordInput,
      model,
      formModal,
      formRef,
      onSubmit,
      vehicles,
      responsibles,
      depots,
      reasons1,
      reasons2,
      reasons3,
      reason1,
      reason2,
      reason3,
      withdrawnDate,
      withdrawnTime,
      prognosisDate,
      prognosisTime,
      readyDate,
      readyTime,
      FORD_MIN_MAX_LENGTH,
      prognosisCertainties,
      showReady,
      isReadyAfterWithdrawn,
      isPrognosisAfterWithdrawn,
      validVehicle,
      periodOverlappSameVehicle,
      vehicleWithdrawalGroupList,
      selectedVehicleWithdrawalUuid,
      addVehicleWithdrawalGroup,
      onSelectVehicleWithdrawal,
      vehicleWithdrawalGroup,
      fetchingGroup,
      hasVehicleWithdrawalGroup,
    }
  },
})
</script>

<style module lang="scss">
.activeClass {
  background: rgba(#00a540, 0.1);
  font-weight: bold;
}
</style>
