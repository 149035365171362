import { instance } from '@/services/avik-api-gateway'
import { VehicleWithdrawalPenaltyAssessment } from '@/types/vehicle-withdrawal-penalty-assessment'
import { AxiosResponse } from 'axios'

export function updateVehicleWithdrawalPenaltyAssessment(
  uuid: string,
  body: Omit<
    VehicleWithdrawalPenaltyAssessment,
    'uuid' | 'vehicleWithdrawalUuid' | 'logs'
  >
): Promise<AxiosResponse<VehicleWithdrawalPenaltyAssessment>> {
  return instance.put(`/vehicle-withdrawal-penalty-assessment/${uuid}`, body)
}
