import { instance } from '@/services/avik-api-gateway'
import { VehicleWithdrawal } from '@/types/vehicle-withdrawal'
import { AxiosResponse } from 'axios'

export function getVehicleWithdrawals(period: {
  from: string
  to: string
}): Promise<
  AxiosResponse<(Omit<VehicleWithdrawal, 'ford'> & { ford: null | string })[]>
> {
  return instance.get(`/vehicle-withdrawal`, {
    params: {
      from: period.from,
      to: period.to,
    },
  })
}
