import { instance } from '@/services/avik-api-gateway'
import { AxiosResponse } from 'axios'
import { Vehicle } from '@/types/vehicle'

export interface GetValidVehicleParams {
  from: string
  to: string
}

let abortController: AbortController

export function getValidVehicle(
  params: GetValidVehicleParams
): Promise<AxiosResponse<Vehicle[]>> {
  if (abortController) {
    abortController.abort()
    abortController = new AbortController()
  } else {
    abortController = new AbortController()
  }

  return instance.get(`/valid-vehicle`, {
    params,
    signal: abortController.signal,
  })
}
