import { Ref, ref, ComputedRef, computed } from 'vue'
import { useLoading, Loading } from '@/composable/useLoading'
import { VehicleWithdrawal } from '@/types/vehicle-withdrawal'
import { getVehicleWithdrawals } from '@/api/vehicle-withdrawal/getVehicleWithdrawals'
import { createVehicleWithdrawal } from '@/api/vehicle-withdrawal/createVehicleWithdrawal'
import { updateVehicleWithdrawal } from '@/api/vehicle-withdrawal/updateVehicleWithdrawal'
import { deleteVehicleWithdrawal } from '@/api/vehicle-withdrawal/deleteVehicleWithdrawal'
import { createVehicleWithdrawalPenaltyAssessment } from '@/api/vehicle-withdrawal-penalty-assessment/createVehicleWithdrawalPenaltyAssessment'
import { updateVehicleWithdrawalPenaltyAssessment } from '@/api/vehicle-withdrawal-penalty-assessment/updateVehicleWithdrawalPenaltyAssessment'
import { getVehicleWithdrawalPenaltyAssessment } from '@/api/vehicle-withdrawal-penalty-assessment/getVehicleWithdrawalPenaltyAssessment'
import { deleteVehicleWithdrawalPenaltyAssessment } from '@/api/vehicle-withdrawal-penalty-assessment/deleteVehicleWithdrawalPenaltyAssessment'
import { VehicleWithdrawalPenaltyAssessment } from '@/types/vehicle-withdrawal-penalty-assessment'
import { DefectCloseReport } from '@/types/defect-close-report'
import { postVehicleDefectFromDb } from '@/api/vehicle-defect-from-db/postVehicleDefectFromDb'

interface UseVehicleWithdrawal {
  data: Ref<VehicleWithdrawal[]>
  fordDefect: Ref<{ [key: string]: DefectCloseReport }>
  fetchAll: (period: {
    from: string
    to: string
  }) => Promise<VehicleWithdrawal[]>
  remove: (uuid: string) => Promise<null>
  add: (body: Partial<VehicleWithdrawal>) => Promise<VehicleWithdrawal>
  update: (body: Partial<VehicleWithdrawal>) => Promise<VehicleWithdrawal>
  loading: ComputedRef<Loading>
  createPenaltyAssessment: (
    body: Partial<VehicleWithdrawalPenaltyAssessment>
  ) => Promise<null>
  updatePenaltyAssessment: (
    uuid: string,
    eventUuid: string,
    body: Omit<
      VehicleWithdrawalPenaltyAssessment,
      'uuid' | 'vehicleWithdrawalUuid' | 'logs'
    >
  ) => Promise<null>
  getPenaltyAssessment: (
    uuid: string
  ) => Promise<VehicleWithdrawalPenaltyAssessment | null>
  deletePenaltyAssessment: (uuid: string, eventUuid: string) => Promise<null>
  penaltyAssessment: Ref<VehicleWithdrawalPenaltyAssessment | null>
}

const fordDefect = ref<{ [key: string]: DefectCloseReport }>({})
const data = ref<VehicleWithdrawal[]>([])
const { state } = useLoading()
const penaltyAssessment = ref<VehicleWithdrawalPenaltyAssessment | null>(null)

const parseFord = (
  x: Omit<VehicleWithdrawal, 'ford'> & { ford: string | null }
) => {
  return {
    ...x,
    ford: x.ford ? x.ford.split(',') : [],
  }
}

export function useVehicleWithdrawal(): UseVehicleWithdrawal {
  async function fetchAll(period: { from: string; to: string }) {
    state.getAll = true
    const vehicleWithdrawal = await new Promise<VehicleWithdrawal[]>(
      (resolve, reject) => {
        return getVehicleWithdrawals(period)
          .then(({ data: VehicleWithdrawals }) => {
            const parsedData = VehicleWithdrawals.map(parseFord)
            data.value = parsedData
            resolve(parsedData)
          })
          .catch(reject)
          .finally(() => {
            state.getAll = false
          })
      }
    )
    const defect_codes = vehicleWithdrawal
      .map((x) => x.ford)
      .flat()
      .map((x) => Number(x))
    await postVehicleDefectFromDb(defect_codes).then(({ data }) => {
      fordDefect.value = data.reduce<{ [key: string]: DefectCloseReport }>(
        (acc, item) => {
          acc[item.defect_number] = item
          return acc
        },
        {}
      )
    })
    return vehicleWithdrawal
  }

  async function remove(uuid: string) {
    state.delete = true
    return new Promise<null>((resolve, reject) => {
      deleteVehicleWithdrawal(uuid)
        .then(() => {
          data.value = data.value.filter((item) => item.uuid !== uuid)
          resolve(null)
        })
        .catch(reject)
        .finally(() => {
          state.delete = false
        })
    })
  }

  async function add(body: Partial<VehicleWithdrawal>) {
    state.create = true
    console.log(body)
    return new Promise<VehicleWithdrawal>((resolve, reject) => {
      createVehicleWithdrawal(body)
        .then(({ data: vehicleWithdrawal }) => {
          const parsedData = parseFord(vehicleWithdrawal)
          data.value.push(parsedData)
          resolve(parsedData)
        })
        .catch(reject)
        .finally(() => {
          state.create = false
        })
    })
  }

  async function update(body: Partial<VehicleWithdrawal>) {
    state.update = true
    return new Promise<VehicleWithdrawal>((resolve, reject) => {
      updateVehicleWithdrawal(body)
        .then(({ data: vehicleWithdrawal }) => {
          const parsedData = parseFord(vehicleWithdrawal)
          data.value = data.value.map((item) => {
            if (item.uuid === parsedData.uuid) {
              return {
                ...item,
                ...parsedData,
              }
            }

            return item
          })
          resolve(parsedData)
        })
        .catch(reject)
        .finally(() => {
          state.update = false
        })
    })
  }

  async function createPenaltyAssessment(
    body: Partial<VehicleWithdrawalPenaltyAssessment>
  ) {
    state.update = true
    return new Promise<null>((resolve, reject) => {
      createVehicleWithdrawalPenaltyAssessment(body)
        .then((res) => {
          data.value = data.value.map((item) => {
            if (item.uuid === body.vehicleWithdrawalUuid) {
              return {
                ...item,
                penaltyAssessment: res.data,
              }
            }

            return item
          })
          resolve(null)
        })
        .catch(reject)
        .finally(() => {
          state.update = false
        })
    })
  }

  async function updatePenaltyAssessment(
    uuid: string,
    vehicleWithdrawalUuid: string,
    body: Omit<
      VehicleWithdrawalPenaltyAssessment,
      'uuid' | 'vehicleWithdrawalUuid' | 'logs'
    >
  ) {
    state.update = true
    return new Promise<null>((resolve, reject) => {
      updateVehicleWithdrawalPenaltyAssessment(uuid, body)
        .then((res) => {
          data.value = data.value.map((item) => {
            if (item.uuid === vehicleWithdrawalUuid) {
              return {
                ...item,
                penaltyAssessment: res.data,
              }
            }

            return item
          })

          resolve(null)
        })
        .catch(reject)
        .finally(() => {
          state.update = false
        })
    })
  }

  async function getPenaltyAssessment(eventUuid: string) {
    return getVehicleWithdrawalPenaltyAssessment(eventUuid)
      .then(({ data }) => {
        if (data) {
          penaltyAssessment.value = data
        } else {
          penaltyAssessment.value = null
        }

        return data
      })
      .catch(() => {
        penaltyAssessment.value = null
        return null
      })
  }

  async function deletePenaltyAssessment(
    uuid: string,
    vehicleWithdrawalUuid: string
  ) {
    state.update = true
    return new Promise<null>((resolve, reject) => {
      deleteVehicleWithdrawalPenaltyAssessment(uuid)
        .then(() => {
          data.value = data.value.map((item) => {
            if (item.uuid === vehicleWithdrawalUuid) {
              return {
                ...item,
                penaltyAssessment: null,
              }
            }

            return item
          })

          resolve(null)
        })
        .catch(reject)
        .finally(() => {
          state.update = false
        })
    })
  }

  return {
    data,
    fordDefect,
    fetchAll,
    add,
    remove,
    update,
    loading: computed(() => state),
    createPenaltyAssessment,
    updatePenaltyAssessment,
    getPenaltyAssessment,
    deletePenaltyAssessment,
    penaltyAssessment,
  }
}
