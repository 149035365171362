<template>
  <q-dialog v-model="formModal.state.show" :persistent="false">
    <VehicleWithdrawalFormModal v-if="formModal.state.show" />
  </q-dialog>
  <q-dialog v-model="deleteModal.state.show" :persistent="false">
    <VehicleWithdrawalDeleteModal v-if="deleteModal.state.show" />
  </q-dialog>
  <q-dialog v-model="fordModal.state.show" :persistent="false">
    <EventFordModal v-if="fordModal.state.show" />
  </q-dialog>
  <q-dialog v-model="logModal.state.show" :persistent="false">
    <DeviationLogModal v-if="logModal.state.show" />
  </q-dialog>
  <q-dialog v-model="penaltyAssessmentModal.state.show" :persistent="false">
    <VehicleWithdrawalPenaltyAssessmentModal
      v-if="penaltyAssessmentModal.state.show"
    />
  </q-dialog>

  <q-table
    :class="$style.stickyHeader"
    :loading="loading.getAll"
    :filter="filterText"
    :rows-per-page-options="[0]"
    :rows="filteredVehicleWithdrawals"
    :columns="columnsWithActions"
    row-key="uuid"
    style="height: calc(100vh - 50px - 68px)"
    flat
    dense
    :pagination="{
      sortBy: 'ready',
      descending: false,
    }"
    @row-dblclick="(e, row) => actionEdit(row)"
  >
    <template v-slot:top>
      <h6 class="q-ma-none">Avställningar</h6>

      <q-btn
        v-if="can('masterdata.vehicleWithdrawal.create')"
        icon-right="mdi-plus-circle-outline"
        class="q-ml-md"
        label="Lägg till"
        color="primary"
        @click="openCreateFormModal"
      />

      <q-space />

      <AppFieldDate
        :square="false"
        dense
        v-model="period.from"
        readonly
        hide-bottom-space
        label="Från"
        class="q-mr-sm"
      />

      <AppFieldDate
        :square="false"
        dense
        v-model="period.to"
        readonly
        label="Till"
        hide-bottom-space
        class="q-mr-sm"
      />

      <q-select
        v-if="currentProject?.name === 'krosatag'"
        outlined
        dense
        style="width: 200px"
        class="q-mr-md"
        v-model="selectedFilterWorkshop"
        clearable
        :options="filterWorkshops"
        label="Verkstadstillhörighet"
      />

      <q-select
        outlined
        dense
        style="width: 170px"
        class="q-mr-md"
        v-model="selectedFilterDepot"
        clearable
        :options="filterDepots"
        label="Avställningsdepå"
      />

      <q-btn-dropdown
        outline
        color="grey-7"
        class="q-mr-md"
        style="height: 40px"
        :label="getFilterReadyTypeLabel"
      >
        <q-list dense>
          <q-item
            tag="label"
            clickable
            v-for="filterReady of filterReadyTypes"
            :key="filterReady.value"
          >
            <q-item-section avatar>
              <q-radio v-model="selectedFilterReady" :val="filterReady.value" />
            </q-item-section>
            <q-item-section>
              <q-item-label>{{ filterReady.name }}</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>

      <q-input
        style="width: 100%; max-width: 300px"
        debounce="300"
        v-model="filterText"
        placeholder="Sök..."
        dense
        outlined
      >
        <template v-slot:append>
          <q-icon name="mdi-magnify" />
        </template>
      </q-input>

      <q-btn
        :loading="exporting"
        @click="onExport"
        class="q-ml-md"
        icon-right="mdi-file-export-outline"
        label="Export"
        color="grey-7"
        outline
      />
    </template>

    <template v-slot:body-cell-prognosis="props">
      <q-td :props="props">
        <div
          :class="[
            isPrognosisOverdue(props.row.ready, props.value) &&
              $style.prognosisWarningBox,
          ]"
        >
          {{ props.value }}
        </div>
      </q-td>
    </template>

    <template v-slot:body-cell-ford="props">
      <q-td :props="props">
        <div style="flex-direction: column" class="flex">
          <template v-for="(ford, $i) in props.value" :key="$i">
            <q-btn
              outline
              dense
              :color="
                fordDefect[ford]?.closing_report_date_and_time ? 'primary' : ''
              "
              size="12px"
              class="q-mb-xs q-mr-xs"
              :disable="!can('deviationManagement.vehicleDefect.list')"
              @click.stop="onClickFordChip(ford)"
            >
              {{ ford }}
            </q-btn>
          </template>
        </div>
      </q-td>
    </template>

    <template v-slot:body-cell-comment="props">
      <q-td :props="props">
        <div style="width: 100%">
          <div style="white-space: pre-wrap">
            {{ props.value }}
          </div>
        </div>
      </q-td>
    </template>

    <template v-slot:body-cell-_action="props">
      <q-td :props="props">
        <q-btn-dropdown
          size="sm"
          flat
          round
          color="grey-7"
          dropdown-icon="mdi-dots-vertical"
          content-class="no-border-radius"
        >
          <q-list>
            <q-item
              clickable
              v-close-popup
              @click="actionItem.action(props.row)"
              v-for="actionItem in actionItems"
              :key="actionItem.label"
            >
              <q-item-section avatar>
                <q-icon size="xs" :name="actionItem.icon" />
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ actionItem.label }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </q-td>
    </template>
  </q-table>
</template>

<script lang="ts">
import { computed, defineComponent, provide, ref, watch } from 'vue'
import { useVehicleWithdrawal } from '@/composable/useVehicleWithdrawal'
import { QTable } from 'quasar'
import { VehicleWithdrawal } from '@/types/vehicle-withdrawal'
import { useProfile } from '@/composable/useProfile'
import { useModal } from '@/composable/useModal'
import VehicleWithdrawalFormModal from '@/components/vehicle-withdrawal/VehicleWithdrawalFormModal.vue'
import VehicleWithdrawalDeleteModal from '@/components/vehicle-withdrawal/VehicleWithdrawalDeleteModal.vue'
import { exportExcel } from '@/services/export-excel'
import { useVehicle } from '@/composable/useVehicle'
import { useVehicleWithdrawalResponsible } from '@/composable/useVehicleWithdrawalResponsible'
import { useVehicleWithdrawalDepot } from '@/composable/useVehicleWithdrawalDepot'
import { useVehicleWithdrawalReason } from '@/composable/useVehicleWithdrawalReason'
import EventFordModal from '@/components/event/EventFordModal.vue'
import { getVehicleDefect } from '@/api/vehicle-defect/getVehicleDefect'
import AppFieldDate from '@/components/AppFieldDate.vue'
import { startOfMonth, subMonths, format } from 'date-fns'
import DeviationLogModal from '@/components/deviation/DeviationLogModal.vue'
import { getLog } from '@/api/log/getLog'
import { isAfter } from 'date-fns'
import VehicleWithdrawalPenaltyAssessmentModal from './VehicleWithdrawalPenaltyAssessmentModal.vue'
import { VehicleWithdrawalPenaltyAssessment } from '@/types/vehicle-withdrawal-penalty-assessment'
import { workshops } from '@/common/workshops'

const filterReadyTypes = [
  { name: 'Alla', value: 'all' },
  { name: 'Påställda', value: 'ready' },
  { name: 'Avställda', value: 'withdrawn' },
]

export default defineComponent({
  name: 'VehicleWithdrawalTable',

  components: {
    VehicleWithdrawalFormModal,
    VehicleWithdrawalDeleteModal,
    EventFordModal,
    AppFieldDate,
    DeviationLogModal,
    VehicleWithdrawalPenaltyAssessmentModal,
  },

  setup() {
    const logModal = useModal()
    const fordModal = useModal()
    const penaltyAssessmentModal = useModal()
    const { currentProject } = useProfile()
    provide('ford-modal', fordModal)
    provide('log-modal', logModal)
    provide('penalty-assessment-modal', penaltyAssessmentModal)
    const {
      loading,
      data: vehicleWithdrawals,
      fetchAll: fetchAllVehicleWithdrawal,
      getPenaltyAssessment,
      fordDefect,
    } = useVehicleWithdrawal()
    const { fetchAll: fetchAllVehicles } = useVehicle()
    const { fetchAll: fetchAllResponsibilities } =
      useVehicleWithdrawalResponsible()
    const { fetchAll: fetchAllDepots } = useVehicleWithdrawalDepot()
    const { fetchAll: fetchAllReasons } = useVehicleWithdrawalReason()
    const { can } = useProfile()
    const formModal = useModal()
    const deleteModal = useModal()

    const exporting = ref(false)

    const period = ref({
      from: format(subMonths(startOfMonth(new Date()), 2), 'yyyy-MM-dd'),
      to: format(new Date(), 'yyyy-MM-dd'),
    })

    watch(
      () => period.value,
      (value) => {
        if (+new Date(value.to) < +new Date(value.from)) {
          fetchAllVehicleWithdrawal({
            from: value.to,
            to: value.from,
          })
        } else {
          fetchAllVehicleWithdrawal(value)
        }
      },
      {
        immediate: true,
        deep: true,
      }
    )

    const selectedFilterReady = ref('withdrawn')
    const selectedFilterDepot = ref('')
    const selectedFilterWorkshop = ref('')

    provide('form-modal', formModal)
    provide('delete-modal', deleteModal)

    const columns = computed(() => {
      return [
        {
          align: 'left',
          name: 'vehicleTypeName',
          label: 'Littera',
          field: (v: VehicleWithdrawal) => v?.vehicle?.vehicleType?.name,
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
        {
          align: 'left',
          name: 'vehicleName',
          label: 'Fordon',
          field: (v: VehicleWithdrawal) => v?.vehicle?.name,
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
        {
          align: 'left',
          name: 'vehicleWithdrawalResponsible',
          label: 'Ansvarig',
          field: (v: VehicleWithdrawal) =>
            v?.vehicleWithdrawalResponsible?.name,
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
        {
          align: 'left',
          name: 'workshop',
          label: 'Verkstadstillhörighet',
          field: (v: VehicleWithdrawal) => v?.vehicle?.workshop,
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
        {
          align: 'left',
          name: 'vehicleWithdrawalDepot',
          label: 'Avställningsdepå',
          field: (v: VehicleWithdrawal) => v?.vehicleWithdrawalDepot?.name,
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
        {
          align: 'left',
          name: 'withdrawn',
          label: 'Avställd',
          field: 'withdrawn',
          format: (v: Date) => format(new Date(v), 'yyyy-MM-dd HH:mm'),
          sortable: true,
        },
        {
          align: 'left',
          name: 'prognosis',
          label: 'Prognos',
          field: 'prognosis',
          format: (v: Date) =>
            v ? format(new Date(v), 'yyyy-MM-dd HH:mm') : null,
          sortable: true,
        },
        {
          align: 'left',
          name: 'ready',
          label: 'Påställd',
          field: 'ready',
          format: (v: Date | null) =>
            v ? format(new Date(v), 'yyyy-MM-dd HH:mm') : null,
          sortable: true,
        },
        {
          align: 'left',
          name: 'comment',
          label: 'Kommentar',
          field: 'comment',
          sortable: true,
        },
        {
          label: 'OEU',
          field: 'penaltyAssessment',
          required: true,
          align: 'left',
          sortable: true,
          name: 'penaltyAssessment',
          format: (v: VehicleWithdrawalPenaltyAssessment) => {
            return v === null
              ? currentProject.value?.name === 'krosatag'
                ? 'Nej'
                : 'Saknas'
              : v.penalty
              ? 'Ja'
              : 'Nej'
          },
          permission: 'event.penaltyAssessment',
          style: 'width: 60px; min-width: 60px; max-width: 60px',
        },
        {
          align: 'left',
          name: 'reason1',
          label: 'Anledning till avställning',
          field: (v: VehicleWithdrawal) => v?.vehicleWithdrawalReason?.reason1,
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
        {
          align: 'left',
          name: 'reason2',
          label: 'Orsak 1',
          field: (v: VehicleWithdrawal) => v?.vehicleWithdrawalReason?.reason2,
          sortable: true,
        },
        {
          align: 'left',
          name: 'reason3',
          label: 'Orsak 2',
          field: (v: VehicleWithdrawal) => v?.vehicleWithdrawalReason?.reason3,
          sortable: true,
        },
        {
          align: 'left',
          name: 'ford',
          label: 'Ford skadenr.',
          field: 'ford',
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
      ]
        .filter((x) => {
          if (!x.permission) return true
          return can(x.permission)
        })
        .filter((x) =>
          currentProject.value?.name === 'krosatag'
            ? true
            : x.name !== 'workshop'
        )
    })

    const columnsWithActions = computed<QTable['columns']>(() => {
      return [
        {
          label: '',
          field: '_action',
          required: true,
          align: 'left ',
          name: '_action',
          sortable: false,
        },
        ...columns.value,
      ] as QTable['columns']
    })

    const actionEdit = (row: Record<string, unknown>) => {
      formModal.openModal({
        mode: 'update',
        data: row,
        cb: fetchModalData,
      })
    }

    const actionItems = computed(() =>
      [
        {
          icon: 'mdi-pencil',
          label: 'Redigera',
          permission: `masterdata.vehicleWithdrawal.update`,
          action: actionEdit,
        },
        {
          label: 'Vitesbedömning',
          icon: 'mdi-alpha-v-box-outline',
          permission: 'event.penaltyAssessment',
          action: (row: VehicleWithdrawal) => {
            penaltyAssessmentModal?.openModal({
              data: row,
              cb: async () => {
                await getPenaltyAssessment(row.uuid)
                return Promise.resolve()
              },
            })
          },
        },
        {
          icon: 'mdi-view-list',
          label: 'Logg',
          permission: `masterdata.vehicleWithdrawal.list`,
          action: (row: Record<string, unknown>) => {
            logModal?.openModal({
              mode: 'vehicleWithdrawn',
              cb: async (setData) => {
                if (!row.uuid) return Promise.resolve()
                const { data } = await getLog(row.uuid as string)
                setData(data)
                return Promise.resolve()
              },
            })
          },
        },
        {
          icon: 'mdi-delete',
          label: 'Ta bort',
          permission: `masterdata.vehicleWithdrawal.delete`,
          action: (row: Record<string, unknown>) => {
            deleteModal.openModal({
              data: row,
            })
          },
        },
      ].filter((actionItem) => can(actionItem.permission))
    )

    async function onExport() {
      exporting.value = true

      const exportData = {
        name: 'Avställningar',
        sheets: [
          {
            name: 'Avställningar',
            data: filteredVehicleWithdrawals.value.map((item) => {
              return (columns.value as QTable['columns'])?.reduce<{
                [name: string]: unknown
              }>((acc, col) => {
                const value =
                  typeof col.field === 'function'
                    ? col.field(item)
                    : item[col.name as keyof VehicleWithdrawal]

                if (col.format) {
                  acc[col.label] = col.format(value, null)
                } else {
                  acc[col.label] = value
                }

                return acc
              }, {})
            }),
          },
        ],
      }

      exportExcel(exportData)
      exporting.value = false
    }

    async function fetchModalData() {
      await Promise.all([
        // fetchValidVehicles({
        //   from: format(setDay(new Date(), -7), 'yyyy-MM-dd'),
        //   to: format(setDay(new Date(), 7), 'yyyy-MM-dd'),
        // }),
        fetchAllVehicles(),
        fetchAllResponsibilities(),
        fetchAllDepots(),
        fetchAllReasons(),
      ])

      return Promise.resolve()
    }

    function openCreateFormModal() {
      formModal.openModal({
        mode: 'create',
        cb: fetchModalData,
      })
    }

    function onClickFordChip(defectNumber: number) {
      fordModal?.openModal({
        cb: async (setData) => {
          await getVehicleDefect(defectNumber)
            .then(({ data }) => {
              setData(data)
            })
            .catch((error) => {
              setData(error.response.data.message)
            })

          return Promise.resolve()
        },
      })
    }

    const getFilterReadyTypeLabel = computed(() => {
      return (
        filterReadyTypes.find((x) => x.value === selectedFilterReady.value)
          ?.name || '-'
      )
    })

    const filterWorkshops = workshops

    const filterDepots = computed(() => {
      return [
        ...new Set(
          vehicleWithdrawals.value
            .map((x) => x.vehicleWithdrawalDepot?.name)
            .filter(Boolean)
        ),
      ]
    })

    const filteredVehicleWithdrawals = computed(() => {
      return vehicleWithdrawals.value
        .filter((x) => {
          if (selectedFilterReady.value === 'all') return true

          if (selectedFilterReady.value === 'ready') return x.ready

          if (selectedFilterReady.value === 'withdrawn') return !x.ready
        })
        .filter((x) => {
          return (
            !selectedFilterWorkshop.value ||
            selectedFilterWorkshop.value === x.vehicle?.workshop
          )
        })
        .filter((x) => {
          return (
            !selectedFilterDepot.value ||
            selectedFilterDepot.value === x.vehicleWithdrawalDepot?.name
          )
        })
    })

    function isPrognosisOverdue(ready: string | null, prognosisDate: string) {
      return !ready && isAfter(new Date(), new Date(prognosisDate))
    }

    return {
      formModal,
      deleteModal,
      columnsWithActions,
      loading,
      vehicleWithdrawals,
      exporting,
      can,
      actionItems,
      onExport,
      openCreateFormModal,
      filterText: ref(''),
      actionEdit,
      fordModal,
      onClickFordChip,
      selectedFilterReady,
      filterReadyTypes,
      getFilterReadyTypeLabel,
      filteredVehicleWithdrawals,
      period,
      logModal,
      isPrognosisOverdue,
      penaltyAssessmentModal,
      filterDepots,
      selectedFilterDepot,
      currentProject,
      selectedFilterWorkshop,
      filterWorkshops,
      fordDefect,
    }
  },
})
</script>

<style lang="scss" module>
$tableHeight: calc(100vh - 118px);

.stickyHeader {
  /* height or max-height is important */
  height: $tableHeight !important;
  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th {
    /* bg color is important for th; just specify one */
    background-color: white;
  }
  thead tr th {
    position: sticky;
    z-index: 2;
  }
  thead tr:first-child th {
    top: 0;
    z-index: 1;
  }
  /* this is when the loading indicator appears */
  &.q-table--loading thead tr:last-child th {
    /* height of all previous header rows */
    top: 48px;
    z-index: 3;
  }

  .q-table th {
    white-space: nowrap !important;
  }

  tr:first-child th:first-child {
    /* highest z-index */
    z-index: 3;
  }

  td:first-child {
    z-index: 1;
  }

  td:first-child,
  th:first-child {
    position: sticky;
    left: 0;
    background: #fff;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.prognosisWarningBox {
  border: 1px solid red;
  display: inline-block;
  padding: 0 4px;
  border-radius: 4px;
  font-weight: bold;
  color: red;
}
</style>
