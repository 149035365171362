
import { computed, defineComponent, provide, ref, watch } from 'vue'
import { useVehicleWithdrawal } from '@/composable/useVehicleWithdrawal'
import QTable from 'quasar/src/components/table/QTable.js';
import { VehicleWithdrawal } from '@/types/vehicle-withdrawal'
import { useProfile } from '@/composable/useProfile'
import { useModal } from '@/composable/useModal'
import VehicleWithdrawalFormModal from '@/components/vehicle-withdrawal/VehicleWithdrawalFormModal.vue'
import VehicleWithdrawalDeleteModal from '@/components/vehicle-withdrawal/VehicleWithdrawalDeleteModal.vue'
import { exportExcel } from '@/services/export-excel'
import { useVehicle } from '@/composable/useVehicle'
import { useVehicleWithdrawalResponsible } from '@/composable/useVehicleWithdrawalResponsible'
import { useVehicleWithdrawalDepot } from '@/composable/useVehicleWithdrawalDepot'
import { useVehicleWithdrawalReason } from '@/composable/useVehicleWithdrawalReason'
import EventFordModal from '@/components/event/EventFordModal.vue'
import { getVehicleDefect } from '@/api/vehicle-defect/getVehicleDefect'
import AppFieldDate from '@/components/AppFieldDate.vue'
import { startOfMonth, subMonths, format } from 'date-fns'
import DeviationLogModal from '@/components/deviation/DeviationLogModal.vue'
import { getLog } from '@/api/log/getLog'
import { isAfter } from 'date-fns'
import VehicleWithdrawalPenaltyAssessmentModal from './VehicleWithdrawalPenaltyAssessmentModal.vue'
import { VehicleWithdrawalPenaltyAssessment } from '@/types/vehicle-withdrawal-penalty-assessment'
import { workshops } from '@/common/workshops'

const filterReadyTypes = [
  { name: 'Alla', value: 'all' },
  { name: 'Påställda', value: 'ready' },
  { name: 'Avställda', value: 'withdrawn' },
]

export default defineComponent({
  name: 'VehicleWithdrawalTable',

  components: {
    VehicleWithdrawalFormModal,
    VehicleWithdrawalDeleteModal,
    EventFordModal,
    AppFieldDate,
    DeviationLogModal,
    VehicleWithdrawalPenaltyAssessmentModal,
  },

  setup() {
    const logModal = useModal()
    const fordModal = useModal()
    const penaltyAssessmentModal = useModal()
    const { currentProject } = useProfile()
    provide('ford-modal', fordModal)
    provide('log-modal', logModal)
    provide('penalty-assessment-modal', penaltyAssessmentModal)
    const {
      loading,
      data: vehicleWithdrawals,
      fetchAll: fetchAllVehicleWithdrawal,
      getPenaltyAssessment,
      fordDefect,
    } = useVehicleWithdrawal()
    const { fetchAll: fetchAllVehicles } = useVehicle()
    const { fetchAll: fetchAllResponsibilities } =
      useVehicleWithdrawalResponsible()
    const { fetchAll: fetchAllDepots } = useVehicleWithdrawalDepot()
    const { fetchAll: fetchAllReasons } = useVehicleWithdrawalReason()
    const { can } = useProfile()
    const formModal = useModal()
    const deleteModal = useModal()

    const exporting = ref(false)

    const period = ref({
      from: format(subMonths(startOfMonth(new Date()), 2), 'yyyy-MM-dd'),
      to: format(new Date(), 'yyyy-MM-dd'),
    })

    watch(
      () => period.value,
      (value) => {
        if (+new Date(value.to) < +new Date(value.from)) {
          fetchAllVehicleWithdrawal({
            from: value.to,
            to: value.from,
          })
        } else {
          fetchAllVehicleWithdrawal(value)
        }
      },
      {
        immediate: true,
        deep: true,
      }
    )

    const selectedFilterReady = ref('withdrawn')
    const selectedFilterDepot = ref('')
    const selectedFilterWorkshop = ref('')

    provide('form-modal', formModal)
    provide('delete-modal', deleteModal)

    const columns = computed(() => {
      return [
        {
          align: 'left',
          name: 'vehicleTypeName',
          label: 'Littera',
          field: (v: VehicleWithdrawal) => v?.vehicle?.vehicleType?.name,
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
        {
          align: 'left',
          name: 'vehicleName',
          label: 'Fordon',
          field: (v: VehicleWithdrawal) => v?.vehicle?.name,
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
        {
          align: 'left',
          name: 'vehicleWithdrawalResponsible',
          label: 'Ansvarig',
          field: (v: VehicleWithdrawal) =>
            v?.vehicleWithdrawalResponsible?.name,
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
        {
          align: 'left',
          name: 'workshop',
          label: 'Verkstadstillhörighet',
          field: (v: VehicleWithdrawal) => v?.vehicle?.workshop,
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
        {
          align: 'left',
          name: 'vehicleWithdrawalDepot',
          label: 'Avställningsdepå',
          field: (v: VehicleWithdrawal) => v?.vehicleWithdrawalDepot?.name,
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
        {
          align: 'left',
          name: 'withdrawn',
          label: 'Avställd',
          field: 'withdrawn',
          format: (v: Date) => format(new Date(v), 'yyyy-MM-dd HH:mm'),
          sortable: true,
        },
        {
          align: 'left',
          name: 'prognosis',
          label: 'Prognos',
          field: 'prognosis',
          format: (v: Date) =>
            v ? format(new Date(v), 'yyyy-MM-dd HH:mm') : null,
          sortable: true,
        },
        {
          align: 'left',
          name: 'ready',
          label: 'Påställd',
          field: 'ready',
          format: (v: Date | null) =>
            v ? format(new Date(v), 'yyyy-MM-dd HH:mm') : null,
          sortable: true,
        },
        {
          align: 'left',
          name: 'comment',
          label: 'Kommentar',
          field: 'comment',
          sortable: true,
        },
        {
          label: 'OEU',
          field: 'penaltyAssessment',
          required: true,
          align: 'left',
          sortable: true,
          name: 'penaltyAssessment',
          format: (v: VehicleWithdrawalPenaltyAssessment) => {
            return v === null
              ? currentProject.value?.name === 'krosatag'
                ? 'Nej'
                : 'Saknas'
              : v.penalty
              ? 'Ja'
              : 'Nej'
          },
          permission: 'event.penaltyAssessment',
          style: 'width: 60px; min-width: 60px; max-width: 60px',
        },
        {
          align: 'left',
          name: 'reason1',
          label: 'Anledning till avställning',
          field: (v: VehicleWithdrawal) => v?.vehicleWithdrawalReason?.reason1,
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
        {
          align: 'left',
          name: 'reason2',
          label: 'Orsak 1',
          field: (v: VehicleWithdrawal) => v?.vehicleWithdrawalReason?.reason2,
          sortable: true,
        },
        {
          align: 'left',
          name: 'reason3',
          label: 'Orsak 2',
          field: (v: VehicleWithdrawal) => v?.vehicleWithdrawalReason?.reason3,
          sortable: true,
        },
        {
          align: 'left',
          name: 'ford',
          label: 'Ford skadenr.',
          field: 'ford',
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
      ]
        .filter((x) => {
          if (!x.permission) return true
          return can(x.permission)
        })
        .filter((x) =>
          currentProject.value?.name === 'krosatag'
            ? true
            : x.name !== 'workshop'
        )
    })

    const columnsWithActions = computed<QTable['columns']>(() => {
      return [
        {
          label: '',
          field: '_action',
          required: true,
          align: 'left ',
          name: '_action',
          sortable: false,
        },
        ...columns.value,
      ] as QTable['columns']
    })

    const actionEdit = (row: Record<string, unknown>) => {
      formModal.openModal({
        mode: 'update',
        data: row,
        cb: fetchModalData,
      })
    }

    const actionItems = computed(() =>
      [
        {
          icon: 'mdi-pencil',
          label: 'Redigera',
          permission: `masterdata.vehicleWithdrawal.update`,
          action: actionEdit,
        },
        {
          label: 'Vitesbedömning',
          icon: 'mdi-alpha-v-box-outline',
          permission: 'event.penaltyAssessment',
          action: (row: VehicleWithdrawal) => {
            penaltyAssessmentModal?.openModal({
              data: row,
              cb: async () => {
                await getPenaltyAssessment(row.uuid)
                return Promise.resolve()
              },
            })
          },
        },
        {
          icon: 'mdi-view-list',
          label: 'Logg',
          permission: `masterdata.vehicleWithdrawal.list`,
          action: (row: Record<string, unknown>) => {
            logModal?.openModal({
              mode: 'vehicleWithdrawn',
              cb: async (setData) => {
                if (!row.uuid) return Promise.resolve()
                const { data } = await getLog(row.uuid as string)
                setData(data)
                return Promise.resolve()
              },
            })
          },
        },
        {
          icon: 'mdi-delete',
          label: 'Ta bort',
          permission: `masterdata.vehicleWithdrawal.delete`,
          action: (row: Record<string, unknown>) => {
            deleteModal.openModal({
              data: row,
            })
          },
        },
      ].filter((actionItem) => can(actionItem.permission))
    )

    async function onExport() {
      exporting.value = true

      const exportData = {
        name: 'Avställningar',
        sheets: [
          {
            name: 'Avställningar',
            data: filteredVehicleWithdrawals.value.map((item) => {
              return (columns.value as QTable['columns'])?.reduce<{
                [name: string]: unknown
              }>((acc, col) => {
                const value =
                  typeof col.field === 'function'
                    ? col.field(item)
                    : item[col.name as keyof VehicleWithdrawal]

                if (col.format) {
                  acc[col.label] = col.format(value, null)
                } else {
                  acc[col.label] = value
                }

                return acc
              }, {})
            }),
          },
        ],
      }

      exportExcel(exportData)
      exporting.value = false
    }

    async function fetchModalData() {
      await Promise.all([
        // fetchValidVehicles({
        //   from: format(setDay(new Date(), -7), 'yyyy-MM-dd'),
        //   to: format(setDay(new Date(), 7), 'yyyy-MM-dd'),
        // }),
        fetchAllVehicles(),
        fetchAllResponsibilities(),
        fetchAllDepots(),
        fetchAllReasons(),
      ])

      return Promise.resolve()
    }

    function openCreateFormModal() {
      formModal.openModal({
        mode: 'create',
        cb: fetchModalData,
      })
    }

    function onClickFordChip(defectNumber: number) {
      fordModal?.openModal({
        cb: async (setData) => {
          await getVehicleDefect(defectNumber)
            .then(({ data }) => {
              setData(data)
            })
            .catch((error) => {
              setData(error.response.data.message)
            })

          return Promise.resolve()
        },
      })
    }

    const getFilterReadyTypeLabel = computed(() => {
      return (
        filterReadyTypes.find((x) => x.value === selectedFilterReady.value)
          ?.name || '-'
      )
    })

    const filterWorkshops = workshops

    const filterDepots = computed(() => {
      return [
        ...new Set(
          vehicleWithdrawals.value
            .map((x) => x.vehicleWithdrawalDepot?.name)
            .filter(Boolean)
        ),
      ]
    })

    const filteredVehicleWithdrawals = computed(() => {
      return vehicleWithdrawals.value
        .filter((x) => {
          if (selectedFilterReady.value === 'all') return true

          if (selectedFilterReady.value === 'ready') return x.ready

          if (selectedFilterReady.value === 'withdrawn') return !x.ready
        })
        .filter((x) => {
          return (
            !selectedFilterWorkshop.value ||
            selectedFilterWorkshop.value === x.vehicle?.workshop
          )
        })
        .filter((x) => {
          return (
            !selectedFilterDepot.value ||
            selectedFilterDepot.value === x.vehicleWithdrawalDepot?.name
          )
        })
    })

    function isPrognosisOverdue(ready: string | null, prognosisDate: string) {
      return !ready && isAfter(new Date(), new Date(prognosisDate))
    }

    return {
      formModal,
      deleteModal,
      columnsWithActions,
      loading,
      vehicleWithdrawals,
      exporting,
      can,
      actionItems,
      onExport,
      openCreateFormModal,
      filterText: ref(''),
      actionEdit,
      fordModal,
      onClickFordChip,
      selectedFilterReady,
      filterReadyTypes,
      getFilterReadyTypeLabel,
      filteredVehicleWithdrawals,
      period,
      logModal,
      isPrognosisOverdue,
      penaltyAssessmentModal,
      filterDepots,
      selectedFilterDepot,
      currentProject,
      selectedFilterWorkshop,
      filterWorkshops,
      fordDefect,
    }
  },
})
