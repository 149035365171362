<template>
  <q-page>
    <VehicleWithdrawalTable />
  </q-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import VehicleWithdrawalTable from '@/components/vehicle-withdrawal/VehicleWithdrawalTable.vue'

export default defineComponent({
  name: 'VehicleWithdrawal',

  components: {
    VehicleWithdrawalTable,
  },

  setup() {
    return {}
  },
})
</script>
