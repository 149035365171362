import { instance } from '@/services/avik-api-gateway'
import { VehicleWithdrawal } from '@/types/vehicle-withdrawal'
import { AxiosResponse } from 'axios'

export function updateVehicleWithdrawal(
  body: Partial<VehicleWithdrawal>
): Promise<
  AxiosResponse<Omit<VehicleWithdrawal, 'ford'> & { ford: string | null }>
> {
  return instance.put(`/vehicle-withdrawal/${body.uuid}`, {
    ...body,
    ford: body.ford?.length ? body.ford?.join(',') : null,
  })
}
