
import { UseModal } from '@/composable/useModal'
import { useVehicleWithdrawal } from '@/composable/useVehicleWithdrawal'
import { defineComponent, inject } from 'vue'

export default defineComponent({
  name: 'VehicleWithdrawalDeleteModal',

  setup() {
    const { loading, remove } = useVehicleWithdrawal()

    const deleteModal = inject<UseModal<{ uuid: string }>>('delete-modal')

    function onConfirm() {
      if (!deleteModal?.state.data) {
        throw new Error('Ingen uuid i deleteModal data parametrar')
      }

      remove(deleteModal.state.data.uuid)

      deleteModal.closeModal()
    }

    return {
      deleteModal,
      loading,
      onConfirm,
    }
  },
})
