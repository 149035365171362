<template>
  <q-dialog v-model="showConfirmDeleteModal">
    <q-card square>
      <q-card-section class="text-grey-8">
        Är du säker på att du vill ta bort vitesbedömningen?
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          flat
          label="Avbryt"
          @click="showConfirmDeleteModal = false"
          :disable="loading.update"
          type="button"
        />
        <q-btn
          label="Bekräfta"
          color="negative"
          @click="onRemove"
          :loading="loading.update"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-card style="min-width: 500px">
    <q-form ref="formRef" @submit.prevent="onConfirm" autocomplete="off" greedy>
      <q-card-section class="row items-center text-h6">
        Vitesbedömning
      </q-card-section>

      <q-separator />

      <q-card-section>
        <p>Ange OEU (Oväntat eftersläpande underhåll)</p>
        <div class="row">
          <div class="col-12">
            <q-option-group
              inline
              v-model="model.penalty"
              :options="[
                {
                  label: 'OEU',
                  value: true,
                },
                {
                  label: 'Ej OEU',
                  value: false,
                },
              ]"
              type="radio"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 q-pt-md">
            <q-input
              dense
              v-model="model.comment"
              label="Beskrivning"
              type="textarea"
              outlined
              placeholder="Beskrivning..."
            />
          </div>
        </div>
      </q-card-section>

      <q-separator />
      <div v-if="lastUpdated" class="q-px-md text-caption text-grey-8">
        Senast uppdaterad: {{ formatDate(lastUpdated.created) }} |
        {{ lastUpdated.user.email }}
      </div>
      <q-separator />

      <q-card-actions align="left" class="q-pa-md">
        <q-btn
          v-if="mode === 'update'"
          flat
          label="Ta bort"
          color="negative"
          type="button"
          @click="showConfirmDeleteModal = true"
        />
        <q-space />
        <q-btn
          flat
          label="Avbryt"
          @click="closeModal"
          type="button"
          :disable="loading.update"
        />
        <q-btn
          :label="mode === 'create' ? 'Spara' : 'Uppdatera'"
          color="primary"
          :loading="loading.update"
          type="submit"
        />
      </q-card-actions>
    </q-form>
  </q-card>
</template>

<script lang="ts">
import { UseModal } from '@/composable/useModal'
import { QForm } from 'quasar'
import { defineComponent, inject, ref } from 'vue'
import { required as requiredRule } from '@/common/formValidationRules'
import { v4 as uuidv4 } from 'uuid'
import { format } from 'date-fns'
import { useVehicleWithdrawal } from '@/composable/useVehicleWithdrawal'
import { VehicleWithdrawal } from '@/types/vehicle-withdrawal'

export default defineComponent({
  name: 'VehicleWithdrawalPenaltyAssessmentModal',

  setup() {
    const showConfirmDeleteModal = ref(false)
    const penaltyAssessmentModal = inject<UseModal<VehicleWithdrawal>>(
      'penalty-assessment-modal'
    )

    const formRef = ref<QForm>()
    const {
      createPenaltyAssessment,
      updatePenaltyAssessment,
      deletePenaltyAssessment,
      loading,
      penaltyAssessment,
    } = useVehicleWithdrawal()

    const model = ref({
      uuid: uuidv4(),
      vehicleWithdrawalUuid: penaltyAssessmentModal?.state?.data?.uuid || '',
      penalty: false,
      comment: '',
    })

    const lastUpdated = penaltyAssessment.value
      ? penaltyAssessment.value.logs[penaltyAssessment.value.logs.length - 1]
      : null

    const mode = penaltyAssessment.value ? 'update' : 'create'

    if (penaltyAssessment.value) {
      model.value.uuid = penaltyAssessment.value.uuid
      model.value.penalty = penaltyAssessment.value.penalty
      model.value.comment = penaltyAssessment.value.comment
    }

    const closeModal = () => {
      penaltyAssessmentModal?.closeModal()
    }

    const onConfirm = async () => {
      const isValid = await formRef.value?.validate()
      if (!isValid) return

      if (mode === 'create') {
        await createPenaltyAssessment(model.value)
      } else {
        await updatePenaltyAssessment(
          model.value.uuid,
          model.value.vehicleWithdrawalUuid,
          {
            penalty: model.value.penalty,
            comment: model.value.comment,
          }
        )
      }

      closeModal()
    }

    const onRemove = async () => {
      await deletePenaltyAssessment(
        model.value.uuid,
        model.value.vehicleWithdrawalUuid
      )
      showConfirmDeleteModal.value = false
      closeModal()
    }

    const formatDate = (d: string) => format(new Date(d), 'yyyy-MM-dd HH:mm')

    return {
      closeModal,
      onConfirm,
      onRemove,
      formRef,
      model,
      requiredRule,
      showConfirmDeleteModal,
      loading,
      mode,
      lastUpdated,
      formatDate,
    }
  },
})
</script>
