import { instance } from '@/services/avik-api-gateway'
import { VehicleWithdrawalPenaltyAssessment } from '@/types/vehicle-withdrawal-penalty-assessment'
import { AxiosResponse } from 'axios'

export function getVehicleWithdrawalPenaltyAssessment(
  uuid: string
): Promise<AxiosResponse<VehicleWithdrawalPenaltyAssessment>> {
  return instance.get(
    `/vehicle-withdrawal/${uuid}/vehicle-withdrawal-penalty-assessment`
  )
}
