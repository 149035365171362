import { Ref, ref, ComputedRef, computed } from 'vue'
import { Vehicle } from '@/types/vehicle'
import {
  getValidVehicle,
  GetValidVehicleParams,
} from '@/api/valid-vehicle/getValidVehicle'
import { useLoading, Loading } from '@/composable/useLoading'

interface UseValidVehicle {
  data: Ref<Vehicle[]>
  fetchAll: (params: GetValidVehicleParams) => Promise<Vehicle[]>
  loading: ComputedRef<Loading>
}

const data = ref<Vehicle[]>([])
const { state } = useLoading()

export function useValidVehicle(): UseValidVehicle {
  async function fetchAll(params: GetValidVehicleParams) {
    state.getAll = true

    return new Promise<Vehicle[]>((resolve, reject) => {
      getValidVehicle(params)
        .then(({ data: vehicles }) => {
          data.value = vehicles || []
          resolve(vehicles)
        })
        .catch(reject)
        .finally(() => {
          state.getAll = false
        })
    })
  }

  return {
    data,
    fetchAll,
    loading: computed(() => state),
  }
}
